.fieldVisitMarker {
  background-size: cover;
  width: 30px;
  height: 42px;
  cursor: pointer;
}

.mapboxgl-popup {
  width: fit-content;
  overflow-x: scroll;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  overflow-x: scroll;
}

.fieldVisitImagesContainer {
  display: flex;
  width: 1000px;
}

.fieldVisitImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding: 0;
}

.fieldVisitImageContainer:not(:last-child) {
  margin-right: 15px;
}

.fieldVisitImageContainer h4 {
  margin-top: 5px;
  font-size: 1.5rem;
}

.fieldVisitImage {
  width: 150px;
  border-radius: 10px;
  cursor: pointer;
}
