.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}

.push-left-enter {
  /* transform: translate(100%); */
  transform: translate(0);
}

.push-left-enter-active {
  transform: translate(0);
  transition: all 1s ease-out;
}

.push-left-exit {
  transform: translate(0);
}

.push-left-exit-active {
  /* transform: translate(100%); */
  transform: translate(0);
  transition: all 1s ease-out;
}

.MuiAlert-message {
  font-family: "Be Vietnam", sans-serif;
  font-size: 1.2rem;
}
