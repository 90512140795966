.Dashboard__container {
  padding: 6.6rem 4.6rem;
}

.Dashboard__due-amount {
  font-family: "Be Vietnam", sans-serif;
  font-size: 3.3rem;
  color: #333f4c;
  font-weight: 600;
  opacity: 86%;
}

.Dashboard__due-amount-container {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.Dashboard__due-payment-icon {
  margin: -3.5rem -3.3rem;
}

.Dashboard__due-amount-text {
  font-size: 1.8rem;
  color: #637381;
  font-family: "Be Vietnam", sans-serif;
  letter-spacing: 0.64px;
  font-weight: 400;
  width: 25rem;
}

.Dashboard__due-amount-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Dashboard__due-amount-content button {
  margin-top: 1.9rem;
  width: fit-content;
}

.Dashboard__data-cards {
  display: flex;
  flex-direction: column;
}

.Dashboard__data-cards > *:not(:last-child) {
  margin-bottom: 3.5rem;
}

.Dashboard__main-cards {
  grid-column: 1 / span 2;
}

.Dashboard__table-card {
  margin-top: 3.5rem;
  height: 50rem !important;
  overflow-y: scroll;
}

.Dashboard__table-text {
  color: #637381;
  font-size: 1.3rem;
  font-family: "Be Vietnam", sans-serif;
  font-weight: 400;
  letter-spacing: 0.48px;

  margin-top: 0.8rem;
}
