.Login {
  display: flex;

  position: relative;

  height: 100vh;
  padding: 1.3rem 0.9rem;

  &__heading {
    font-family: "Be Vietnam", sans-serif;
    color: #212b36;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 1.15px;

    margin-top: 4.7rem;
  }

  &__img {
    width: 100%;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100vh;
    margin-left: 8.1rem;
    flex-grow: 1;
  }

  &__form {
    width: 60rem;

    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      width: 100%;
      border-left: 0.8rem solid #00ab55;

      padding-left: 1.8rem;
    }

    &-title {
      font-family: "Be Vietnam", sans-serif;
      font-size: 2.6rem;
      color: #212b36;
      font-weight: 500;
      letter-spacing: 1.02px;
    }

    &-subtitle {
      font-family: "Be Vietnam", sans-serif;
      font-size: 1.6rem;
      color: #637381;
      font-weight: 400;
      letter-spacing: 0.64px;
    }

    &-alert {
      display: flex;
      align-items: center;

      width: 100%;
      margin-top: 3.8rem;

      font-family: "Be Vietnam", sans-serif !important;
      font-size: 1.2rem !important;
      letter-spacing: 0.58px !important;
    }

    & .MuiFormControl-root {
      width: 100%;

      &:not(:last-child) {
        margin-top: 3rem !important;
      }

      &:last-child {
        margin-top: 3.2rem;
      }
    }

    & .MuiInputLabel-root,
    .MuiOutlinedInput-input {
      font-size: 1.2rem !important;
      font-family: "Be Vietnam", sans-serif;
      font-weight: 500;
      color: #a4afba;
      letter-spacing: 0.51px;
    }

    & .MuiOutlinedInput-input {
      color: #212b36;
    }

    &-forgot-btn {
      display: block;
      text-align: right;
      width: 100%;
      margin-top: 2.8rem;
    }

    & .PrimaryButton {
      width: 100%;
      margin-top: 5.1rem;
    }
  }
}

.LoginStatusSnackbar > div {
  display: flex;
  align-items: center;

  font-family: "Be Vietnam", sans-serif !important;
  font-size: 1.2rem !important;
  letter-spacing: 0.58px !important;
}
