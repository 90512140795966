.Credit-line {
  padding: 5rem 3.5rem;
  margin-top: 14.2rem;

  &__add-remark {
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(194, 194, 194, 50%);
    transition: all 0.4s;

    &:hover {
      border-bottom: 1px solid rgb(194, 194, 194);
    }
  }

  &__priority-button {
    color: #00ab55;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    -webkit-text-underline-offset: 0.5rem;
    text-decoration-thickness: 2px;
    -webkit-text-decoration-thickness: 2px;
    cursor: pointer;
    transition: color 0.3s;

    &--disabled {
      opacity: 0.7;
      color: grey !important;
      cursor: default;
    }

    &:hover {
      color: #027b55;
    }
  }

  &__text {
    font-size: 1.3rem;
    font-family: "Be Vietnam", sans-serif;
    color: #637381;
    font-weight: 400;
    letter-spacing: 0.48px;

    margin-top: 2rem;
  }

  &__grid {
    grid-column-gap: 8.3rem;
    grid-row-gap: 4.2rem;
    margin-top: 4.5rem;
  }

  &__status {
    font-size: 1.1rem;
    color: #fea790;
    font-family: "Be Vietnam", sans-serif;
    font-weight: 500;
    letter-spacing: 0.35px;
    text-transform: uppercase;

    margin-top: 1rem;

    &--verified {
      color: #00aa55;
    }

    &--finance {
      color: #76cafe;
    }

    &--asset {
      color: #b98007;
    }

    &--crop {
      color: #5de684;
    }

    &--personal {
      color: #ffe26c;
    }
  }

  &__card-img {
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    height: 7.3rem;
    cursor: pointer;
  }

  &__detail-text {
    font-family: "Be Vietnam";
    font-weight: 500;
    font-size: 1.2rem;
    color: #212b36;
    letter-spacing: 0.38px;

    & b {
      font-weight: 600;
    }
  }

  &__action-bar {
    position: fixed;
    top: 0;
    right: 0;

    z-index: 10;

    background-color: #fff;
    box-shadow: rgba(#d9d9d9, 0.64) 0 4px 1.1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 75vw;
    padding: 2.2rem 3.5rem;
  }

  &__approve-buttons {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &__approve-as-loan {
    margin-left: 0;
  }

  &__reject {
    background-color: #fda78d !important;
    box-shadow: rgba(#fda78d, 35%) 0 8px 1.6rem !important;
  }
}

div[data-testid="viewer"] {
  height: 60rem !important;
}

.apexcharts-legend-series {
  transform: translateX(1rem) !important;
}

.apexcharts-legend-text {
  font-family: "Be Vietnam", sans-serif !important;
  display: inline-block;
  font-size: 1.5rem !important;
  margin-left: 1rem;
}

::-webkit-file-upload-button {
  outline: none;
  border: none;
  font-size: 1.4rem;
  background-color: #00ab55;
  color: #fff;
  font-family: "Be Vietnam", sans-serif;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
}
