.Ledger {
  &__container {
    padding: 5.8rem 2.7rem;
  }

  &__main-card {
    flex: 0.66;
    margin-right: 1.8rem;
  }

  &__subheading {
    font-family: "Be Vietnam", sans-serif;
    font-size: 2rem;
    opacity: 0.85;
    color: #212b36;
    font-weight: 600;
  }
}

.MuiTypography-root.MuiTypography-body2.MuiTablePagination-selectLabel.css-16epbcf-MuiTypography-root {
  font-size: 1.2rem;
}

p.MuiTypography-root.MuiTypography-body2.MuiTablePagination-displayedRows.css-16epbcf-MuiTypography-root {
  font-size: 1.2rem;
}
