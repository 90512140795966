button.PrimaryButton {
  background-color: #00ab55;
  padding: 1.2rem 2rem;
  color: #fff;
  font-family: "Be Vietnam", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.54px;

  text-transform: capitalize;

  box-shadow: rgba(0, 171, 85, 0.35) 0 8px 1.6rem;

  border: none;
  border-radius: 0.8rem;

  cursor: pointer;

  transition: background-color 0.3s, box-shadow 0.4s;
}

button.SecondaryButton {
  background-color: #fda78d;
  padding: 1.2rem 2rem;
  color: #fff;
  font-family: "Be Vietnam", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.54px;

  text-transform: capitalize;

  border: none;
  border-radius: 0.8rem;

  cursor: pointer;

  transition: all 0.3s;
}

button.SecondaryButton:hover {
  background-color: #b92238 !important;
}

button.PrimaryButton:hover {
  background-color: #027b55 !important;
  box-shadow: none;
}
