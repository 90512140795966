.MuiInputLabel-root,
.MuiOutlinedInput-input {
  font-size: 1.2rem !important;
  font-family: "Be Vietnam", sans-serif;
  font-weight: 500;
  letter-spacing: 0.51px;
}

.Search {
  margin-top: 2rem !important;
}
