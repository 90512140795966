.Accordion {
  box-shadow: none !important;
  border-bottom: none !important;

  padding: 0.5rem 1rem;
}

.Accordion h6 {
  font-family: "Be Vietnam", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
}

.MuiAccordion-root {
  border-bottom: none !important;
}

.MuiAccordion-root.Mui-expanded {
  box-shadow: rgb(145 158 171 / 24%) 0px 8px 16px 0px !important;
  border-radius: 0.8rem;
  border-bottom: none !important;
}
